import { createContentfulClient } from '../libs/contentful';
import firebaseApp from '../libs/firebaseInit';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import config from '../libs/config';
import Layout from '../components/common/Layout';
import Meta from '../components/common/Meta';
import AlertKv from '../components/section/common/AlertKv';
import Kv from '../components/section/top/Kv';
import Yap from '../components/section/top/Yap';
import Salads from '../components/section/top/Salads';
import Location from '../components/section/top/Location';
import Sites from '../components/section/top/Sites';
import Initiatives from '../components/section/top/Initiatives';
import Careers from '../components/section/common/Careers';

export default function Home({
    tasks,
    messages,
    megaBanners,
    subBanners,
    yapTitle,
    yapEntries,
    signatureSalads,
    serviceBanners,
    serviceSiteFooterBanner,
}) {
    return (
        <Layout>
            <Meta
                title="CRISP SALAD WORKS│クリスプサラダワークス"
                description="カスタムサラダ専門店「CRISP SALAD WORKS」です。CRISPらしいレストラン体験で「A Small, Good Thing. (ちょっとした心の交流)」と、野菜たっぷりのサラダを届けていきたいなって思ってます。"
            />
            <AlertKv messages={messages} />
            <Kv mega_banners={megaBanners} sub_banners={subBanners} />
            {/* <Yap yapEntries={yapEntries} yapTitle={yapTitle} /> */}
            <Salads salads={signatureSalads} />
            <Location />
            <Sites serviceBanners={serviceBanners} />
            <Initiatives />
            <Careers serviceSiteFooterBanner={serviceSiteFooterBanner} />
        </Layout>
    );
}

export const getStaticProps = async () => {
    const client = createContentfulClient();
    const tasks = [];
    const db = getFirestore(firebaseApp);
    const querySnapshot = await getDocs(
        collection(db, config.nameOfBrandShops)
    );
    querySnapshot.forEach((doc, index) => {
        const data = { id: doc.id };
        tasks.push(data);
    });
    const messages = await getAllMessages(client);
    const megaBanners = await getAllMegaBanner(client);
    const subBanners = await getSubBanner(client);
    const yapTitle = await getYapTitle(client);
    const yapEntries = await getYapEntry(client);
    const signatureSalads = await getSignatureSalads(client);
    const serviceBanners = await getServiceBanner(client);
    const serviceSiteFooterBanner = await getServiceSiteFooterBanner(client);

    return {
        props: {
            tasks,
            messages,
            megaBanners,
            subBanners,
            yapTitle,
            yapEntries,
            signatureSalads,
            serviceBanners,
            serviceSiteFooterBanner,
        },
    };
};

export async function getAllMessages(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteTopMetaNews',
            order: 'fields.order',
        })
        .catch((error) => {
            return error;
        });
}

export async function getAllMegaBanner(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteTopMegaBanner',
            order: 'fields.order',
        })
        .catch((error) => {
            return error;
        });
}

export async function getSubBanner(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteTopSubBanner',
            order: 'fields.order',
        })
        .catch((error) => {
            return error;
        });
}

export async function getYapTitle(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteSpecialSaladTitle',
            limit: 1,
        })
        .catch((error) => {
            return error;
        });
}

export async function getYapEntry(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteSpecialSalad',
            order: 'fields.order',
            limit: 3,
        })
        .catch((error) => {
            return error;
        });
}

export async function getSignatureSalads(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteSignatureSalads',
            order: 'fields.order',
            limit: 12,
        })
        .catch((error) => {
            return error;
        });
}

export async function getServiceBanner(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteTopBanner',
            order: 'fields.order',
        })
        .catch((error) => {
            return error;
        });
}

export async function getServiceSiteFooterBanner(client) {
    return await client
        .getEntries({
            content_type: 'serviceSiteFooterBanner',
        })
        .catch((error) => {
            return error;
        });
}
